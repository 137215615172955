






import { Vue, Component, Prop } from "vue-property-decorator";
import Terminos from "@/components/Common/TerminosDirectoComponent.vue";

@Component({
  components: {
    Terminos
  }
})
export default class TerminosView extends Vue {
  private mounted() {}
}
