






import { Vue, Component, Prop } from "vue-property-decorator";
import Home from "@/components/Home.vue";

@Component({
  components: {
    Home
  }
})
export default class HomeView extends Vue {
  private mounted() {}
}
