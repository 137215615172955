






import { Component, Vue } from "vue-property-decorator";
import LoginComponent from "@/components/auth/LoginComponent.vue";

@Component({
  components: {
    LoginComponent
  }
})
export default class LoginView extends Vue {}
