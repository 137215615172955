
























































































































































































































































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";

@Component
export default class Terminos extends Vue{
  
}
