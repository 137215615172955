





















import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import Terminos from "@/components/Common/Terminos.vue";

@Component({
  components: {
    Terminos
  }
})
export default class TerminosDirectoComponent extends Vue {}
