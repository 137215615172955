






import { Vue, Component, Prop } from "vue-property-decorator";
import HomeBeneficios from "@/components/Beneficios/HomeBeneficios.vue";

@Component({
  components: {
    HomeBeneficios
  }
})
export default class HomeBeneficiosView extends Vue {
  private mounted() {}
}
