






import { Vue, Component, Prop } from "vue-property-decorator";
import Beneficio from "@/components/Beneficios/Beneficio.vue";

@Component({
  components: {
    Beneficio
  }
})
export default class BeneficioView extends Vue {
  private mounted() {}
}
