import { Component, Vue } from "vue-property-decorator";
declare module "vue/types/vue";

@Component
export default class FormValidator extends Vue {
  protected formData: any;
  formIsRequired(value: string) {
    if (value) {
      return true;
    }
    return this.$t("messages.required_field");
  }
  formSelectIsRequired(value: any) {
    if (value.value || value.value == 0) {
      return true;
    }
    return this.$t("messages.required_field");
  }
  formIsEmail(value: string) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(value) === true) {
      return true;
    }
    return this.$t("messages.invalid_email");
  }
  formEmailCofirm(value: string) {
    if (this.formData.email === value) {
      return true;
    }
    return this.$t("messages.not_match_email");
  }
  formEmailAdultoCofirm(value: string) {
    if (this.formData.emailAdulto === value) {
      return true;
    }
    return this.$t("messages.not_match_email");
  }
  formPhoneNumber(value: number) {
    if (value && value > 99999999) {
      return true;
    }
    return this.$t("messages.valid_phone");
  }
  formPhoneCofirm(value: string) {
    if (this.formData.telefono === value) {
      return true;
    }
    return this.$t("messages.not_match_phone");
  }
  formPhoneAdultoCofirm(value: string) {
    if (this.formData.telefonoAdulto === value) {
      return true;
    }
    return this.$t("messages.not_match_phone");
  }
  formPasswordCofirm(value: string) {
    if (this.formData.password === value) {
      return true;
    }
    return this.$t("messages.not_match_password");
  }
  formNewPasswordCofirm(value: string) {
    if (this.formData.plainPassword === value) {
      return true;
    }
    return this.$t("messages.not_match_password");
  }
  formPasswordNewCofirm(value: string) {
    if (this.formData.password_confirmation === value) {
      return true;
    }
    return this.$t("messages.not_match_password");
  }
  formIsPassword(value: string) {
    if (value.length >= 6) {
      return true;
    }
    return this.$t("messages.password_length");
  }
  formValidRut(value: string) {
    if (this.validateRut(value)) {
      return true;
    }
    return this.$t("messages.invalid_rut");
  }
  public validateRut(newValue: string) {
    var texto = newValue;
    var tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    var largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    var invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    var dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    var cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    var dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    var largo = crut.length;
    if (largo < 2) {
      return false;
    }
    if (largo > 2) var rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    var dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    var dvr = "0";
    var suma = 0;
    var mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    var res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      var dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
}
