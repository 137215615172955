






import { Vue, Component, Prop } from "vue-property-decorator";
import Emprendimientos from "@/components/Emprendimientos/Emprendimientos.vue";

@Component({
  components: {
    Emprendimientos
  }
})
export default class EmprendimientosView extends Vue {
  private mounted() {}
}
