


























































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";

@Component
export default class Emprendimientos extends Mixins(FormValidator) {
  private emprendimientos: Array<any> = [];
  private nombreWeb: any = "";
  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getInfoEmprendimientos();
    }
  }

  private getInfoEmprendimientos() {
    this.$axios
      .get("/listado/emprendimientos?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        this.emprendimientos = res.data;
        for (let i = 0; i < this.emprendimientos.length; i++) {
          if (this.emprendimientos[i].foto != null) {
            this.emprendimientos[i].foto =
              " https://s3.us-west-2.amazonaws.com/s3.documentos.tarjetavecino.iquique/" +
              this.emprendimientos[i].foto;
          }
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private irEmp(idComercio: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "DetalleEmp" &&
      this.$router.currentRoute.params.name == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "DetalleEmp",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
}
